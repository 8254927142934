import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "daysInput", "changeButton", "toggleAll", "dateField", "fieldsWrapper", "statusSelect"]

  connect() {
    this.updateWrapperVisibility()
  }

  updateWrapperVisibility() {
    const anyChecked = this.checkboxTargets.some(checkbox => checkbox.checked)
    this.fieldsWrapperTarget.classList.toggle('hidden', !anyChecked)
    this.fieldsWrapperTarget.classList.toggle('flex', anyChecked)
    this.focusDaysInput()
  }

  checkboxChanged() {
    this.updateWrapperVisibility()
  }

  toggleAllCheckboxes() {
    const newState = this.toggleAllTarget.checked
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = newState
    })
    this.updateWrapperVisibility()
  }

  changeDates() {
    const selectedIds = this.getSelectedIds()
    const daysToMove = parseInt(this.daysInputTarget.value, 10)
    const fieldsToUpdate = this.dateFieldTargets
      .filter(field => field.checked)
      .map(field => field.value)

    if (selectedIds.length > 0 && !isNaN(daysToMove) && fieldsToUpdate.length > 0) {
      this.updateOpportunities('/opportunities/update_dates', { ids: selectedIds, days_to_move: daysToMove, fields_to_update: fieldsToUpdate })
    }
  }

  changeStatuses() {
    const selectedIds = this.getSelectedIds()
    const newStatus = this.statusSelectTarget.value

    if (selectedIds.length > 0 && newStatus) {
      this.updateOpportunities('/opportunities/update_statuses', { ids: selectedIds, project_status_id: newStatus })
    }
  }

  getSelectedIds() {
    return this.checkboxTargets
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.dataset.id)
  }

  updateOpportunities(url, data) {
    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify(data)
    }).then(response => {
      if (response.ok) {
        window.location.reload()
      }
    })
  }

  focusDaysInput() {
    if (!this.daysInputTarget.classList.contains('hidden')) {
      this.daysInputTarget.focus();
    }
  }
}