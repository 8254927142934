import SlimSelect from 'slim-select';
import { Controller } from "@hotwired/stimulus";
import { get, patch } from '@rails/request.js'
import _, { debounce } from 'lodash';

// Connects to data-controller="slimselect"
export default class extends Controller {
  static values = {
    options: Object,
    url: String
  };

  connect() {
    this.afterChange = debounce(this.afterChange, 200).bind(this);

    this.slimselect = new SlimSelect({
      select: this.element,
      events: {
        afterChange: this.afterChange,
        search: (search, currentData) => {
          return new Promise((resolve, reject) => {
            if (search.length < 3) {
              return reject('Search must be at least 3 characters')
            }

            const searchString = new URLSearchParams(search).toString();

            fetch(`/projects.json?search_type=simple&search=${searchString}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            })
              .then((response) => response.json())
              .then((data) => {
                // Take the data and create an array of options
                // excluding any that are already selected in currentData
                const options = data
                  .filter((project) => {
                    return !currentData.some((optionData) => {
                      return optionData.value === project.id
                    })
                  })
                  .map((project) => {
                    return {
                      text: `${project.project_number} ${project.name} - ${project.company}`,
                      value: project.id,
                    }
                  })

                resolve(options)
              })
          })
        },
      },
      ...this.optionsValue
    });
  }

  disconnect() {
    this.slimselect.destroy();
  }

  async afterChange(items) {
    const values = items.map(item => item.value);

    await patch(this.urlValue, {
      body: {
        email_thread: {
          project_ids: values
        }
      }
    });
  }
}
