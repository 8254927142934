import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropzone", "overlay", "errorMessage"]
  static values = {
    method: String,
    url: String
  }

  connect() {
    this.dropzoneTarget.addEventListener("dragenter", this.handleDragEnter.bind(this))
    this.dropzoneTarget.addEventListener("dragover", this.handleDragOver.bind(this))
    this.dropzoneTarget.addEventListener("dragleave", this.handleDragLeave.bind(this))
    this.dropzoneTarget.addEventListener("drop", this.handleDrop.bind(this))
    this.dragCounter = 0
  }

  handleDragEnter(event) {
    event.preventDefault()
    this.dragCounter++
    this.showOverlay()
  }

  handleDragOver(event) {
    event.preventDefault()
  }

  handleDragLeave(event) {
    event.preventDefault()
    this.dragCounter--
    if (this.dragCounter === 0) {
      this.hideOverlay()
    }
  }

  handleDrop(event) {
    event.preventDefault()
    this.dragCounter = 0
    this.hideOverlay()

    const files = event.dataTransfer.files
    if (files.length > 0) {
      Array.from(files).forEach(file => this.uploadFile(file))
    }
  }

  showOverlay() {
    this.overlayTarget.classList.remove("hidden")
  }

  hideOverlay() {
    this.overlayTarget.classList.add("hidden")
  }

  uploadFile(file) {
    this.errorMessageTarget.classList.add("hidden")

    const allowedTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'application/pdf',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ]

    if (!allowedTypes.includes(file.type)) {
      this.showErrorMessage(`File type not allowed: ${file.name}. Please upload only images, PDFs, or Excel files.`)
      return
    }

    const formData = new FormData()
    formData.append("file", file)

    fetch(this.urlValue, {
      method: this.methodValue,
      body: formData,
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        "Accept": "application/json"
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw new Error("Network response was not ok")
      })
      .then(data => {
        this.showSuccessMessage(data.message)
      })
      .catch(error => {
        console.error("Error uploading file:", error)
        this.showErrorMessage(`An error occurred while uploading ${file.name}. Please try again.`)
      })
  }
  showSuccessMessage(message) {
    const flashElement = document.createElement("div")
    flashElement.classList.add("flash", "flash-success")
    flashElement.textContent = message
    document.body.appendChild(flashElement)
    setTimeout(() => flashElement.remove(), 5000)
  }

  showErrorMessage(message) {
    this.errorMessageTarget.textContent = message
    this.errorMessageTarget.classList.remove("hidden")
  }
}