import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="xero-signup"
export default class extends Controller {
  connect() {
    setTimeout(() => this.clickButton(), 2000)
  }

  clickButton() {
    this.element.click();
  }
}
