import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['startDate', 'endDate']

  connect() {
    this.formController = this.application.getControllerForElementAndIdentifier(
      this.element.closest('form'),
      'form'
    )
  }

  selectOption(event) {
    event.preventDefault()
    const option = event.currentTarget.dataset.option
    const [startDate, endDate] = this.calculateDateRange(option)

    // Trigger Flatpickr to update its display
    this.startDateTarget._flatpickr.setDate(startDate)
    this.endDateTarget._flatpickr.setDate(endDate)

    // Submit the form
    this.formController.submit()
  }

  calculateDateRange(option) {
    const today = new Date()
    let startDate, endDate
    const currentQuarter = Math.floor(today.getMonth() / 3)

    switch (option) {
      case 'current_month':
        startDate = new Date(today.getFullYear(), today.getMonth(), 1)
        endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0)
        break
      case 'last_month':
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        endDate = new Date(today.getFullYear(), today.getMonth(), 0)
        break
      case 'next_month':
        startDate = new Date(today.getFullYear(), today.getMonth() + 1, 1)
        endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0)
        break
      case 'current_quarter':
        startDate = new Date(today.getFullYear(), currentQuarter * 3, 1)
        endDate = new Date(today.getFullYear(), (currentQuarter + 1) * 3, 0)
        break
      case 'last_quarter':
        startDate = new Date(today.getFullYear(), (currentQuarter - 1) * 3, 1)
        endDate = new Date(today.getFullYear(), currentQuarter * 3, 0)
        break
      case 'next_quarter':
        startDate = new Date(today.getFullYear(), (currentQuarter + 1) * 3, 1)
        endDate = new Date(today.getFullYear(), (currentQuarter + 2) * 3, 0)
        break
    }

    return [startDate, endDate]
  }
}