import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fixedMarkup", "percentageMarkup"]

  connect() {
    this.updateFields()
  }

  updateFields() {
    this.fixedMarkupTarget.addEventListener("input", () => this.clearPercentageMarkup())
    this.percentageMarkupTarget.addEventListener("input", () => this.clearFixedMarkup())
  }

  clearFixedMarkup() {
    if (this.percentageMarkupTarget.value !== "") {
      this.fixedMarkupTarget.value = "0.00"
    }
  }

  clearPercentageMarkup() {
    if (this.fixedMarkupTarget.value !== "") {
      this.percentageMarkupTarget.value = ""
    }
  }
}