import { Controller } from "@hotwired/stimulus";
import { createPopper } from '@popperjs/core';
import _, { debounce } from 'lodash';

// Connects to data-controller="tracked-time"
export default class extends Controller {
  static targets = ['content', 'reference', 'popperTime', 'form']
  static values = {
    open: Boolean
  }

  connect() {
    if (this.hasFormTarget) {
      this.form = this.formTarget;
    } else {
      this.form = this.element;
    }

    this.delayedSubmit = debounce(this.delayedSubmit, 800).bind(this);
  }

  delayedSubmit() {
    this.updateTime();
  }

  updateTime(e) {
    this.form.requestSubmit();
  }

  async show(event) {
    this.contentTarget.classList.remove('hidden');
    const screenSize = window.innerWidth;
    const placement = screenSize > 640 ? "right" : "auto";

    this.popper = createPopper(this.referenceTarget, this.contentTarget, {
      placement: placement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8]
          }
        },
      ],
      onFirstUpdate: () => {
        this.popperTimeTarget.nextSibling.focus();
      }
    })
  }

  // Used for global clicks
  hide(event) {
    if (event.target.nodeType && !event.target.matches(".flatpickr-calendar *, .ss-content *") && this.element.contains(event.target) === false && this.popper) {
      this.popper.destroy()
      this.popper = null
      this.contentTarget.classList.add('hidden');
    }
  }

  close() {
    if (this.popper) {
      this.popper.destroy()
      this.popper = null
      this.contentTarget.classList.add('hidden');
    }
  }
}
