import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin from '@fullcalendar/interaction';

// Connects to data-controller="calendar"
export default class extends Controller {
  static targets = ["calendar"];
  static values = {
    resources: Array,
    events: Array,
  };

  connect() {
    this.calendar = new Calendar(this.calendarTarget, {
      editable: true,
      slotEventOverlap: true,
      resourceOrder: "title",
      schedulerLicenseKey: '0032247324-fcs-1550108260',
      plugins: [resourceTimelinePlugin, interactionPlugin],
      headerToolbar: {
        left: '',
        center: 'title',
        right: 'prev,next,today'
      },
      views: this.viewLayouts(),
      eventClassNames: args => this.eventClassNames(args),
      eventContent: args => this.eventContent(args),
      initialView: 'resourceTimelineMonth',
      resources: this.resourcesValue.map(res => {
        return {
          id: res.id,
          title: res.title,
        }
      }),
      events: this.eventsValue.map(event => {
        return {
          id: event.id,
          resourceId: event.resource_id,
          title: event.title,
          start: event.start,
          end: event.end,
          extendedProps: {
            status: event.status,
            time_zone: event.time_zone,
            assigned: event.assigned,
            percentageComplete: event.percentage_complete,
          }
        }
      }),
      slotMinWidth: 100,
    });

    this.calendar.render();
  }

  disconnect() {
    this.calendar.destroy();
  }

  eventClassNames({ event }) {
    if (event.extendedProps.status === "Completed") {
      return ["event-complete"];
    } else if (event.extendedProps.status === "In Progress") {
      return ["event-tentative"];
    }

    return ["event-normal"];
  }

  eventContent({ event }) {
    let progressColour = "bg-blue-600";

    if (event.extendedProps.status === "Completed") {
      progressColour = "bg-green-600";
    } else if (event.extendedProps.status === "In Progress") {
      progressColour = "bg-yellow-600";
    }

    let html = `
      <div class="event event__hover" id="event_${event.id}">
        <div class="event__title flex items-center justify-between space-x-2">
          <span>${event.title}</span>
          <span class="flex items-center -space-x-1">
            ${event.extendedProps.assigned.map(u => {
      if (u.avatar == null) return `<span class="drum-avatar flex items-center justify-center leading-none bg-blue-500 flex-shrink-0">${u.initials}</span>`

      return `<img src="${u.avatar}" class="flex-shrink-0 drum-avatar " alt="${u.name}" title="${u.name}">`
    }).join('')}
          </span>
        </div>
        <div class="event__client">${event.extendedProps.status}</div>
        <div class="event__dates overflow-auto">
          <span class="mr-4"><strong>Start: </strong> ${event.start.toLocaleString('en-AU', { timeZone: event.extendedProps.time_zone })}</span>
          <span><strong>End: </strong> ${event.end.toLocaleString('en-AU', { timeZone: event.extendedProps.time_zone })}</span>
        </div>
        <div class="event__progress">
          <div class="${progressColour} h-1 rounded-full" style="width: ${event.extendedProps.status == "Completed" ? 100 : event.extendedProps.percentageComplete}%"></div>
        </div>
      </div>
    `;

    return { html }
  }

  viewLayouts() {
    const today = new Date();
    const monthMilliseconds = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) - Date.UTC(today.getFullYear(), today.getMonth(), 1);

    return {
      resourceTimelineMonth: {
        titleFormat: { year: 'numeric', month: 'long', day: 'numeric', weekday: "short" },
        slotLabelFormat: { weekday: "short", day: "numeric" },
        scrollTime: monthMilliseconds,
        resourceAreaWidth: 220,
        scrollTimeReset: false
      },
    }
  }

}
