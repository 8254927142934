import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="timesheet"
export default class extends Controller {
  static targets = ["projectSearch", "projectIdField"]
  static values = { timesheetId: Number, startDate: String, accountUserId: Number }

  addProject(e) {
    const projectProcedureId = e.currentTarget.dataset.id;
    const timesheetId = this.timesheetIdValue || "";
    const startDate = this.startDateValue || "";
    const accountUserId = this.accountUserIdValue || "";

    get(`/timesheets/add_procedure?account_user_id=${accountUserId}&project_procedure_id=${projectProcedureId}&timesheet_id=${timesheetId}&start_date=${startDate}`, { responseKind: "turbo-stream" });
    this.projectSearchTarget.value = "";
  }
}
