import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

// Connects to data-controller="user-select"
export default class extends Controller {
  static targets = [ "selectField", "userIcon", "avatars" ]

  clickIcon() {
    this.userIconTarget.classList.toggle("hidden");
    this.selectFieldTarget.classList.toggle("hidden");

    if (this.hasAvatarsTarget) {
      this.avatarsTarget.classList.toggle("hidden");
    }

    this.userSelect = new SlimSelect({
      select: this.selectFieldTarget,
      settings: {
        closeOnSelect: false,
        placeholderText: "Allocate process"
      },
      events: {
        afterClose: () => {
          this.finalise();
        }
      }
    });

    this.userSelect.open();
  }

  finalise() {
    if (!this.selectFieldTarget.classList.contains('hidden')) {
      this.selectFieldTarget.classList.toggle("hidden");
      this.selectFieldTarget.form.requestSubmit();
    }
  }
}
