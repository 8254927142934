import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'
import _, { debounce } from 'lodash';

// Connects to data-controller="nav-search"
export default class extends Controller {
  static targets = ["searchInput", "resultsContainer"]

  initialize() {
    this.search = debounce(this.search, 500).bind(this);
    useClickOutside(this);
  }

  async search(event) {
    if (event.target.value.length > 3) {
      event.target.form.requestSubmit();
    } else {
      this.resultsContainerTarget.innerHTML = '';
    }
  }

  clickOutside() {
    if (!this.resultsContainerTarget.classList.contains('hidden')) {
      this.resultsContainerTarget.innerHTML = '';
    }
  }

  selectProject(e) {
    let url = new URL(document.location);
    let search_params = url.searchParams;
    search_params.set('project_id', e.currentTarget.dataset.id);
    search_params.set('range', document.getElementById("range").value);
    window.location = url.toString();
  }

  clearSearch(e) {
    let url = e.currentTarget.dataset.url;

    if (url) {
      window.location = url;
    }
  }
}
