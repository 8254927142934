import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="quote-convert"
export default class extends Controller {
  static targets = ["budgetType", "budgetTypeSection"];

  changeConvertMethod(e) {
    const creationType = e.target.value;

    if (creationType === "default") {
      this.budgetTypeSectionTarget.classList.add("hidden");
      this.budgetTypeTarget.value = "project_level";
    } else {
      this.budgetTypeSectionTarget.classList.remove("hidden");
    }
  }
}
