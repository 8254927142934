import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="company"
export default class extends Controller {
  connect() {
  }

  setPrimaryAddress(e) {
    const checkbox = e.target;

    if(checkbox.checked) {
      document.querySelectorAll('.primary-address').forEach(cb => {
        if(cb.id != checkbox.id) {
          cb.checked = false;
        }
      });
    }
  }

  setPinnedContact(e) {
    const checkbox = e.target;

    if(checkbox.checked) {
      document.querySelectorAll('.pinned-contact').forEach(cb => {
        if(cb.id != checkbox.id) {
          cb.checked = false;
        }
      });
    }
  }
}
