import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {
  static target = ["checkboxArea"]

  toggleCheckboxes(e) {
    const toggle = e.target;
    const checkboxes = this.element.querySelectorAll('input[type="checkbox"]');

    checkboxes.forEach((checkbox) => {
      checkbox.checked = toggle.checked;
    });
  }
}
