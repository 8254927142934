import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["date", "procedureSelect", "warningMessage"]

  connect() {
    this.checkExistingTime()
  }

  checkExistingTime() {
    const date = this.dateTarget.value
    const procedureId = this.procedureSelectTarget.value
    if (!date || !procedureId) return

    fetch(`/projects/${this.projectId}/tracked_times/check_existing_time?date=${date}&procedure_id=${procedureId}`)
      .then(response => response.json())
      .then(data => {
        if (data.existing_time !== 0.0) {
          this.warningMessageTarget.textContent = `You've already allocated ${data.existing_time} hours to this stage for this day.`;
          this.warningMessageTarget.classList.remove('hidden')
          this.warningMessageTarget.classList.add('alert')
        } else {
          this.warningMessageTarget.classList.add('hidden')
          this.warningMessageTarget.classList.remove('alert')
        }
      })
  }

  get projectId() {
    return this.element.dataset.projectId
  }
}