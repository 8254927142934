import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dynamic-options"
export default class extends Controller {
  static targets = ['optionsContainer', 'options']

  selectFieldType(e) {
    if (e.target.value == 'select') {
      this.optionsContainerTarget.classList.remove('hidden');
    } else {
      this.optionsContainerTarget.classList.add('hidden');
      this.optionsContainerTarget.classList.innerHTML = '';
    }
  }

  addOption(e) {
    e.preventDefault();
    let newInput = document.createElement('div');
    newInput.className = 'form-group';
    newInput.dataset.dynamicOptionsTarget = 'option';
    newInput.innerHTML = this.optionTemplate();
    this.optionsContainerTarget.appendChild(newInput);
  }

  removeOption(e) {
    e.preventDefault();
    e.target.parentElement.outerHTML = '';
  }

  optionTemplate() {
    return `
      <label>Option Value</label>
      <input type="text" name="options[]" class="form-control" required="required" placeholder="The option name">
      <a href="#" data-action="dynamic-options#removeOption" class="text-xs text-red-500">Remove</a>
    `;
  }

}
