import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="project-number"
export default class extends Controller {
  static targets = ["field"]

  edit(e) {
    e.preventDefault();

    this.fieldTargets.forEach(t => { t.disabled = false });
  }
}
