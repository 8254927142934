import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["billingType", "amountGroup", "percentageGroup"]

  connect() {
    this.toggleFields()
  }

  toggleFields() {
    const selectedType = this.billingTypeTarget.value

    if (selectedType === "fixed_amount") {
      this.amountGroupTarget.classList.remove("hidden")
      this.percentageGroupTarget.classList.add("hidden")
      this.percentageGroupTarget.querySelector('input').value = ''
    } else if (selectedType === "percentage") {
      this.amountGroupTarget.classList.add("hidden")
      this.percentageGroupTarget.classList.remove("hidden")
      this.amountGroupTarget.querySelector('input').value = ''
    } else {
      this.amountGroupTarget.classList.add("hidden")
      this.percentageGroupTarget.classList.add("hidden")
      this.amountGroupTarget.querySelector('input').value = ''
      this.percentageGroupTarget.querySelector('input').value = ''
    }
  }
}