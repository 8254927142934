import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'
import { patch } from '@rails/request.js'

// Connects to data-controller="nested-sortable"
export default class extends Controller {
  static targets = ['sortable']
  static values = {
    resourceName: String,
    paramName: {
      type: String,
      default: 'position'
    },
    responseKind: {
      type: String,
      default: 'html'
    },
    animation: Number,
    handle: String
  }

  initialize() {
    this.onUpdate = this.onUpdate.bind(this)
  }

  connect() {
    if (this.hasSortableTarget) {
      this.sortableTargets.forEach(sortable => {
        const handle = sortable.dataset.sortableHandle;

        Sortable.create(sortable, {
          ...this.defaultOptions,
          ...this.options,
          group: sortable.dataset.sortableGroup,
          handle
        })
      });
    }
  }

  async onUpdate({ item, newIndex }) {
    if (!item.dataset.sortableUpdateUrl) return

    const param = `${item.dataset.sortableResourceName}[${this.paramNameValue}]`

    const data = new FormData()
    data.append(param, newIndex + 1)

    await patch(item.dataset.sortableUpdateUrl, { body: data, responseKind: this.responseKindValue })
    return true
  }

  get options() {
    return {
      animation: this.animationValue || this.defaultOptions.animation || 150,
      onUpdate: this.onUpdate
    }
  }

  get defaultOptions() {
    return {}
  }
}
