import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="contact"
export default class extends Controller {
  static targets = ['contactId', 'name', 'email', 'mobilePhone', 'officePhone', 'title', 'contactType'];

  selectExistingContact(e) {
    const contactId = e.currentTarget.dataset.id;

    if (contactId) {
      this.contactIdTarget.value = contactId;
      this.fetchContact(contactId);
      this.contactTypeTarget.innerHTML = this.existingContact();
    }
  }

  searchableChanged(e) {
    if (e.target.value.length == 0) {
      this.contactIdTarget.value = "";
      this.contactTypeTarget.innerHTML = "";
    }
  }

  async fetchContact(contactId) {
    const response = await get(`/contacts/${contactId}.json`)

    if (response.ok) {
      const json = await response.json;

      this.nameTarget.value = json.full_name;
      this.emailTarget.value = json.email;
      this.mobilePhoneTarget.value = json.mobile_phone;
      this.officePhoneTarget.value = json.office_phone;
      this.titleTarget.value = json.title;
    }
  }

  newContact() {
    return `<p class="text-green-500 font-sm font-medium">Creating a new contact in your Drum account.</p>`;
  }

  existingContact() {
    return `<p class="text-blue-700 text-xs font-medium flex items-center space-x-1 mb-2">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
      </svg>
      <span>Using the existing contact details in your Drum account.</span>
    </p>`;
  }
}
