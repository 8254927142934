import Flatpickr from 'stimulus-flatpickr'

export default class extends Flatpickr {
  connect() {
    super.connect();

    // Workaround for Turbo 8 issue where Flatpickr's custom DOM elements aren't maintained on page morph
    const existingActions = this.element.getAttribute("data-action")
    this.element.setAttribute("data-action", `${existingActions} turbo:morph@window->flatpickr#reconnect`)
  }

  close(selectedDates, dateStr, instance) {
    this.roundToStep(dateStr, instance)
    instance.element.dispatchEvent(new Event('close'))
  }

  reconnect() {
    this.disconnect()
    this.connect()
  }

  change(dates, dateStr, instance) {
    this.roundToStep(dateStr, instance)
  }

  roundToStep(dateStr, instance) {
    if (instance.config.enableTime && instance.config.minuteIncrement && instance.config.minuteIncrement > 1) {
      let minuteIncrement = instance.config.minuteIncrement;

      if (minuteIncrement && minuteIncrement > 0) {
        let minutes = Number(instance.minuteElement.value);

        if (Number.isNaN(minutes)) return;

        let remainder = minutes % minuteIncrement;

        if (remainder === 0) return;

        const result = Math.round(minutes / minuteIncrement) * minuteIncrement;

        instance.minuteElement.value = result;
        instance.setDate(instance.parseDate(dateStr).setMinutes(instance.minuteElement.value));
      }
    }
  }
}
