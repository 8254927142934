import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="single-select"
export default class extends Controller {
  static values = {
    url: String,
    name: String
  }

  change(event) {
    let params = new URLSearchParams()
    params.append(this.nameValue, event.target.selectedOptions[0].value)
    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream"
    })
  }
}
