import { Controller } from "@hotwired/stimulus"
import _, { debounce } from 'lodash';
import { get } from '@rails/request.js'

// Connects to data-controller="cost-combobox"
export default class extends Controller {
  static targets = [
    'results', 'result', 'field', 'supplierHiddenField', 'availableResourceHiddenField', 'disabledField',
    'existingRecordMessage', 'newRecordMessage', 'tradingName', 'existingRecordName'
  ];

  initialize() {
    this.search = debounce(this.search, 500).bind(this)
  }

  async search(e) {
    if (e.target.value.length < 3) {
      this.disabledFieldTargets.forEach(f => f.removeAttribute('disabled'));
      this.resultsTarget.innerHTML = "";
      this.resultsTarget.classList.add('hidden');
      this.supplierHiddenFieldTarget.value = "";
      this.availableResourceHiddenFieldTarget.value = "";
      this.tradingNameTarget.value = "";
      this.existingRecordMessageTarget.classList.add('hidden');
      this.newRecordMessageTarget.classList.add('hidden');
      return;
    }

    this.resultsTarget.innerHTML = "";

    // Search for companies based on the text
    let [resourceResponse, supplierResponse] = await Promise.all([
      await get(`/available_resources.json?search=${e.target.value}`),
      await get(`/companies/search.json?supplier=true&search=${e.target.value}`)
    ]);

    if (resourceResponse.ok && supplierResponse.ok) {
      const resourceJson = await resourceResponse.json;
      const supplierJson = await supplierResponse.json;

      const json = resourceJson.map(resource => {
        return { id: resource.id, name: resource.name, type: "resource" }
      }).concat(
        supplierJson.map(supplier => {
          return {
            id: supplier.id,
            name: supplier.name,
            type: "supplier",
            supplier_status: supplier.supplier_status
          }
        })
      );

      if (json.length > 0) {
        if (this.hasTradingNameTarget) {
          this.newRecordMessageTarget.classList.add('hidden');
          this.tradingNameTarget.value = '';
        }
        this.resultsTarget.classList.remove('hidden');

        json.map(result => {
          const template = this.resultTarget.cloneNode(true);
          template.content.querySelectorAll('li')[0].dataset.id = result.id;
          template.content.querySelectorAll('li')[0].dataset.name = result.name;
          template.content.querySelectorAll('li')[0].dataset.type = result.type;

          const nameSpan = template.content.querySelectorAll('span')[0];
          nameSpan.textContent = result.name;

          if (result.type === "supplier" && result.supplier_status) {
            const statusSpan = document.createElement('span');
            statusSpan.textContent = result.supplier_status.name;
            statusSpan.className = `tag tag--${result.supplier_status.colour} ml-2 text-xs`;
            nameSpan.appendChild(statusSpan);
          }

          this.resultsTarget.append(template.content);
        });
      } else if (this.hasTradingNameTarget) {
        this.newRecordMessageTarget.classList.remove('hidden');
        this.existingRecordMessageTarget.classList.add('hidden');
        this.tradingNameTarget.value = e.target.value;
      }
    }
  }
  selectResult(e) {
    this.resultsTarget.classList.add('hidden');
    this.existingRecordMessageTarget.classList.remove('hidden');
    this.newRecordMessageTarget.classList.add('hidden');
    this.tradingNameTarget.value = "";

    if (e.currentTarget.dataset.type === "supplier") {
      this.supplierHiddenFieldTarget.value = e.currentTarget.dataset.id;
      this.availableResourceHiddenFieldTarget.value = "";
      this.fieldTarget.value = e.currentTarget.dataset.name;
      this.existingRecordNameTarget.textContent = "supplier";
    } else {
      this.availableResourceHiddenFieldTarget.value = e.currentTarget.dataset.id;
      this.supplierHiddenFieldTarget.value = "";
      this.fieldTarget.value = e.currentTarget.dataset.name;
      this.existingRecordNameTarget.textContent = "resource";
    }

    this.disabledFieldTargets.forEach(field => {
      field.value = "";
      field.setAttribute("disabled", "")
    });
  }
}
