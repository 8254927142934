import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs"
import { patch } from '@rails/request.js'

export default class extends Controller {
  static targets = ["resources"]

  connect() {
    const dropResource = this.dropResource.bind(this);

    new Sortable(this.element, {
      animation: 150,
      group: {
        name: "shared"
      },
      handle: ".handle",
      onEnd(event) {
        dropResource(event.clone.dataset.resourceName, event.clone.dataset.updateUrl, event.to.dataset.id, event.newIndex + 1);
      }
    });
  }

  dropResource(resourceName, updateUrl, parentId, position) {
    let body = {};
    body[resourceName] = {
      budget_procedure_id: parentId,
      position
    };

    patch(updateUrl, { body: JSON.stringify(body), responseKind: "turbo-stream" });
  }
}
