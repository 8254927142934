import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropzone", "overlay", "errorMessage", "loader"]
  static values = {
    url: String
  }

  connect() {
    this.dropzoneTarget.addEventListener("dragenter", this.handleDragEnter.bind(this))
    this.dropzoneTarget.addEventListener("dragover", this.handleDragOver.bind(this))
    this.dropzoneTarget.addEventListener("dragleave", this.handleDragLeave.bind(this))
    this.dropzoneTarget.addEventListener("drop", this.handleDrop.bind(this))
    this.dragCounter = 0
  }

  handleDragEnter(event) {
    event.preventDefault()
    this.dragCounter++
    this.showOverlay()
  }

  handleDragOver(event) {
    event.preventDefault()
  }

  handleDragLeave(event) {
    event.preventDefault()
    this.dragCounter--
    if (this.dragCounter === 0) {
      this.hideOverlay()
    }
  }

  handleDrop(event) {
    event.preventDefault()
    this.dragCounter = 0
    this.hideOverlay()

    const files = event.dataTransfer.files
    if (files.length > 0) {
      Array.from(files).forEach(file => this.uploadFile(file))
    }
  }

  showOverlay() {
    this.overlayTarget.classList.remove("hidden")
  }

  hideOverlay() {
    this.overlayTarget.classList.add("hidden")
  }

  uploadFile(file) {
    this.showLoader()
    this.errorMessageTarget.classList.add("hidden")

    const formData = new FormData()
    formData.append("file", file)

    fetch(this.urlValue, {
      method: "POST",
      body: formData,
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        "Accept": "application/json"
      }
    })
    .then(response => {
      if (response.ok) {
        return response.json()
      }
      throw new Error("Network response was not ok")
    })
    .then(data => {
      this.hideLoader()
      this.showSuccessMessage(data.message)
      this.refreshAttachments()
    })
    .catch(error => {
      this.hideLoader()
      console.error("Error uploading file:", error)
      this.showErrorMessage(`An error occurred while uploading ${file.name}. Please try again.`)
    })
  }

  showLoader() {
    this.loaderTarget.classList.remove("hidden")
  }

  hideLoader() {
    this.loaderTarget.classList.add("hidden")
  }

  showSuccessMessage(message) {
    const flashElement = document.createElement("div")
    flashElement.classList.add("flash", "flash-success")
    flashElement.textContent = message
    document.body.appendChild(flashElement)
    setTimeout(() => flashElement.remove(), 5000)
  }

  showErrorMessage(message) {
    this.errorMessageTarget.textContent = message
    this.errorMessageTarget.classList.remove("hidden")
  }

  refreshAttachments() {
    const attachmentsContainer = this.element.querySelector("#attachments-container")

    if (attachmentsContainer) {
      attachmentsContainer.reload()
    }
  }
}