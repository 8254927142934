import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="project-template-booking"
export default class extends Controller {
  static targets = ['defaultResource'];

  async selectBookingType(e) {
    this.defaultResourceTarget.length = 1;
    const id = e.target.value;

    const response = await get(`/booking_types/${id}.json`)

    if (response.ok) {
      const json = await response.json;

      json.resources.map(resource => {
        let opt = document.createElement("option");
        opt.value = resource.id;
        opt.innerHTML = resource.name;

        this.defaultResourceTarget.append(opt);
      });
    }
  }
}
