import SlimSelect from 'slim-select';
import { Controller } from "@hotwired/stimulus";
import { post } from '@rails/request.js'
import _, { debounce } from 'lodash';

// Connects to data-controller="slimselect"
export default class extends Controller {
  static values = {
    options: Object,
    url: String
  };

  connect() {
    this.afterChange = debounce(this.afterChange, 200).bind(this);

    this.slimselect = new SlimSelect({
      select: this.element,
      events: {
        afterChange: this.afterChange
      },
      ...this.optionsValue
    });
  }

  disconnect() {
    this.slimselect.destroy();
  }

  async afterChange(items) {
    if (!this.urlValue) return;

    const values = items.map(item => item.value);

    await post(this.urlValue, {
      responseKind: "turbo-stream",
      body: { values: values }
    });
  }
}
