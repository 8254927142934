import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { structure: String, persisted: Boolean }
  static targets = ["total", "cost", "button", "summarySection", "simpleBudgetTemplate", "detailedBudgetTemplate"]

  connect() {
    if (this.structureValue === 'simple') {
      this.showSimpleBudget();
    } else {
      this.showDetailedBudget();
      this.setDetailedBudgetButton();
    }
  }

  changeStructure(event) {
    this.summarySectionTarget.innerHTML = '';

    if (event.target.value === 'simple') {
      this.showSimpleBudget();
      this.buttonTarget.value = 'Save';
    } else {
      this.showDetailedBudget();
      this.setDetailedBudgetButton();
    }
  }

  showSimpleBudget() {
    const simpleBudget = this.simpleBudgetTemplateTarget.content.cloneNode(true);
    this.summarySectionTarget.appendChild(simpleBudget);
  }

  showDetailedBudget() {
    const detailedBudget = this.detailedBudgetTemplateTarget.content.cloneNode(true);
    this.summarySectionTarget.appendChild(detailedBudget);
  }

  setDetailedBudgetButton() {
    if (this.persistedValue) {
      this.buttonTarget.value = 'Save';
    } else {
      this.buttonTarget.value = 'Save & Edit Budget Items';
    }
  }

  updateTotal() {
    let total = 0

    this.costTargets.forEach((cost) => {
      total += parseFloat(cost.value)
    })

    const formatter = new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
    });

    if (isNaN(total)) {
      total = 0
    }

    this.totalTarget.textContent = formatter.format(total)
  }
}
