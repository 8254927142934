import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="booking"
export default class extends Controller {
  static targets = ["resources", "startDate", "endDate", "conflict", "formButton"];
  static values = {
    length: Number,
    id: Number
  };

  async changeType(e) {
    this.resourcesTarget.length = 1;

    const id = e.target.value;
    const startDate = this.startDateTarget.value;
    const endDate = this.endDateTarget.value;
    const response = await get(`/available_resources.json?booking_type_id=${id}&start_date=${startDate}&end_date=${endDate}`);

    if (response.ok) {
      const json = await response.json;

      json.map(resource => {
        let opt = document.createElement("option");
        opt.value = resource.id;
        opt.innerHTML = resource.name;

        this.resourcesTarget.append(opt);
      });
    }
  }

  changeStart(e) {
    const newStart = e.target._flatpickr.selectedDates[0];
    const endDate = this.endDateTarget._flatpickr.selectedDates[0];

    if (this.hasLengthValue && this.lengthValue) {
      const startPlusDefault = newStart.setHours(newStart.getHours() + this.lengthValue);

      if (newStart > endDate) {
        return this.endDateTarget._flatpickr.setDate(startPlusDefault);
      }
    }

    const startPlusOneHour = newStart.setHours(newStart.getHours() + 1);
    if (newStart > endDate) {
      return this.endDateTarget._flatpickr.setDate(startPlusOneHour);
    }

    this.formButtonTarget.value = "Save";
  }

  changeEnd(e) {
    const newEnd = e.target._flatpickr.selectedDates[0];
    const startDate = this.startDateTarget._flatpickr.selectedDates[0];

    if (newEnd < startDate) {
      this.startDateTarget._flatpickr.setDate(newEnd);
    }

    this.formButtonTarget.value = "Save";
  }

  changeResource(e) {
    if (this.hasConflictTarget) {
      this.formButtonTarget.value = "Save";
    }
  }
}
