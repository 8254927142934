import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="timesheet-overheads"
export default class extends Controller {
  static targets = ["overheads", "rightChevron", "downChevron"]

  toggleOpen() {
    this.overheadsTarget.classList.toggle("hidden");
    this.rightChevronTarget.classList.toggle("hidden");
    this.downChevronTarget.classList.toggle("hidden");
  }
}
