import { Controller } from '@hotwired/stimulus'
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  static targets = ['content', 'reference']
  static values = {
    open: Boolean
  }

  async show(event) {
    this.contentTarget.classList.remove('hidden');
    const screenSize = window.innerWidth;
    const placement = screenSize > 640 ? "right" : "auto";

    this.popper = createPopper(this.referenceTarget, this.contentTarget, {
      placement: placement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8]
          }
        },

      ]
    })
  }

  // Used for global clicks
  hide(event) {
    if (event.target.nodeType && !event.target.matches(".flatpickr-calendar *, .ss-content *") && this.element.contains(event.target) === false && this.popper) {
      this.popper.destroy()
      this.popper = null
      this.contentTarget.classList.add('hidden');
    }
  }

  close() {
    if (this.popper) {
      this.popper.destroy()
      this.popper = null
      this.contentTarget.classList.add('hidden');
    }
  }
}