import flatpickr from "flatpickr";
import { Controller } from "@hotwired/stimulus";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

export default class extends Controller {
  connect() {
    this.fp = flatpickr(this.element, {
      altInput: true,
      plugins: [
        new monthSelectPlugin({
          dateFormat: "Y-m-d",
          altFormat: "F Y",
        })
      ]
    });
  }


  disconnect() {
    this.fp.destroy();
  }
}
