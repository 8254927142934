import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static values = {
    startedAt: String,
    duration: Number,
    status: String,
    secondaryStartedAt: String,
    secondaryDuration: Number,
    secondaryStatus: String
  }

  static targets = [
    "time", "timeSecondary", "form", "formSecondary"
  ]

  connect() {
    if (this.statusValue === "active") {
      let duration;

      if (this.durationValue) {
        duration = this.durationValue
      } else if (this.startedAtValue) {
        const startTime = new Date(this.startedAtValue)
        const now = new Date()
        duration = Math.floor((now - startTime) / 1000.0)
      }

      this.startTimeUpdates(duration, "primary")
    }

    if (this.secondaryStatusValue === "active") {
      let duration;

      if (this.secondaryDurationValue) {
        duration = this.secondaryDurationValue
      } else if (this.secondaryStartedAtValue) {
        const startTime = new Date(this.secondaryStartedAtValue)
        const now = new Date()
        duration = Math.floor((now - startTime) / 1000.0)
      }

      this.startTimeUpdates(duration, "secondary")
    }
  }

  toggle(e) {
    e.preventDefault();
    const timerType = e.currentTarget.dataset.timerType

    if (timerType === "primary") {
      this.formTarget.classList.toggle('timer-inner__hidden')
      this.formSecondaryTarget.classList.remove('timer-inner__hidden')
      this.formSecondaryTarget.classList.add('timer-inner__hidden')
    } else {
      this.formSecondaryTarget.classList.toggle('timer-inner__hidden')
      this.formTarget.classList.remove('timer-inner__hidden')
      this.formTarget.classList.add('timer-inner__hidden')
    }
  }

  startTimeUpdates(durationSeconds, timerType = "primary") {
    const intervalKey = `${timerType}Interval`;
    const startedAtValue = timerType === "primary" ? this.startedAtValue : this.secondaryStartedAtValue;
    const timeTargets = timerType === "primary" ? this.timeTargets : this.timeSecondaryTargets;

    if (this[intervalKey]) {
      clearInterval(this[intervalKey]);
    }

    this[intervalKey] = setInterval(() => {
      const startTime = new Date(startedAtValue);
      const now = new Date();
      const totalSeconds = Math.floor((now - startTime) / 1000) + durationSeconds;

      timeTargets.forEach(target => {
        target.textContent = this.formatTime(totalSeconds);
      });
    }, 1000);
  }

  stopTimeUpdates(timerType = "primary") {
    const intervalKey = `${timerType}Interval`;
    const durationValue = timerType === "primary" ? this.durationValue : this.secondaryDurationValue;
    const timeTargets = timerType === "primary" ? this.timeTargets : this.secondaryTimeTargets;

    if (this[intervalKey]) {
      clearInterval(this[intervalKey]);
    }

    timeTargets.forEach(target => {
      target.textContent = this.formatTime(durationValue);
    });
  }

  formatTime(seconds) {
    // Return 00:00:00 if seconds is null, undefined or NaN
    if (!seconds && seconds !== 0) return "00:00:00"

    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`
  }
}