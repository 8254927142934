import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="email"
export default class extends Controller {
  static targets = ["service", "submit"];

  changeService(event) {
    const selectedEl = event.target[event.target.selectedIndex]
    this.serviceTarget.value = selectedEl.dataset.service;
  }

  submit(event) {
    this.submitTarget.disabled = true;
    this.submitTarget.innerHTML = `
      <button class="btn btn-primary btn-icon">
        <i class="fas fa-circle-notch fa-spin mr-1"></i>
        <span>Sending Email...</span>
      </button>
    `;
  }
}
