import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
    page: { type: Number, default: 1 },
    container: String
  }

  connect() {
    this.intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && (this.pageValue > 1 || this.isAtBottom())) {
          this.loadMore()
        }
      })
    })

    this.intersectionObserver.observe(this.element)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.element)
  }

  isAtBottom() {
    return (window.innerHeight + window.scrollY) >= document.body.offsetHeight
  }

  loadMore() {
    const nextPage = this.pageValue + 1
    const url = new URL(this.urlValue)
    url.searchParams.set('page', nextPage)

    fetch(url, {
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then(response => response.json())
      .then(data => {
        const container = document.querySelector(this.containerValue)
        container.insertAddjacentHTML('beforeend', data.emails)
        this.pageValue = nextPage
        
        if (!data.pagination) {
          this.intersectionObserver.unobserve(this.element)
        }
      })
  }
}