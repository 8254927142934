import { Controller } from "@hotwired/stimulus"
import _, { debounce } from 'lodash';
import { get } from '@rails/request.js'

// Connects to data-controller="combobox"
export default class extends Controller {
  static targets = ['results', 'result', 'field', 'hiddenField', 'disabledField', 'existingRecordMessage', 'newRecordMessage', 'tradingName'];
  static values = { url: String, urlDetails: String, queryParams: Object, excludedIds: Array }

  initialize() {
    this.search = debounce(this.search, 500).bind(this)
  }

  async search(e) {
    if (e.target.value.length < 3) {
      this.disabledFieldTargets.forEach(f => f.removeAttribute('disabled'));
      this.resultsTarget.innerHTML = "";
      this.resultsTarget.classList.add('hidden');
      this.hiddenFieldTarget.value = "";

      if (this.hasTradingNameTarget) {
        this.tradingNameTarget.value = "";
      }
      if (this.hasExistingRecordMessageTarget) {
        this.existingRecordMessageTarget.classList.add('hidden');
      }
      if (this.hasNewRecordMessageTarget) {
        this.newRecordMessageTarget.classList.add('hidden');
      }
      return;
    }

    this.resultsTarget.innerHTML = "";

    let queryParams = this.queryParamsValue;

    // Search for companies based on the text
    if (this.excludedIdsValue.length) {
      queryParams["excluded_ids"] = this.excludedIdsValue;
    }

    const paramsToString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');

    const response = await get(`${this.urlValue}?search=${e.target.value}&${paramsToString}`)

    if (response.ok) {
      const json = await response.json;

      if (json.length > 0) {
        if (this.hasTradingNameTarget) {
          this.newRecordMessageTarget.classList.add('hidden');
          this.tradingNameTarget.value = '';
        }
        this.resultsTarget.classList.remove('hidden');

        json.map(result => {
          const template = this.resultTarget.cloneNode(true);
          template.content.querySelectorAll('li')[0].dataset.id = result.id;
          template.content.querySelectorAll('li')[0].dataset.name = result.name;
          template.content.querySelectorAll('span')[0].textContent = result.name;

          if (this.urlValue.includes('projects')) {
            template.content.querySelectorAll('span')[0].textContent += ` (${result.company})`;
          }

          this.resultsTarget.append(template.content);
        });
      } else if (this.hasTradingNameTarget) {
        this.newRecordMessageTarget.classList.remove('hidden');
        this.existingRecordMessageTarget.classList.add('hidden');
        this.tradingNameTarget.value = e.target.value;
      }
    }
  }

  selectResult(e) {
    this.resultsTarget.classList.add('hidden');
    this.hiddenFieldTarget.value = e.currentTarget.dataset.id;
    this.fieldTarget.value = e.currentTarget.dataset.name;
    if (this.hasExistingRecordMessageTarget) {
      this.existingRecordMessageTarget.classList.remove('hidden');
    }
    this.disabledFieldTargets.forEach(field => {
      field.value = "";
      field.setAttribute("disabled", "")
    });

    if (this.urlDetailsValue.length) {
      get(`${this.urlDetailsValue}?id=${e.currentTarget.dataset.id}`, {
        responseKind: "turbo-stream"
      })
    }

    this.excludedIdsValue = this.excludedIdsValue.concat(parseInt(e.currentTarget.dataset.id));
  }
}
