import { Controller } from "@hotwired/stimulus"
import Cropper from 'cropperjs';

export default class extends Controller {
  static targets = [
    "input",
    "imageCanvas",
    "imageCropButton",
    "cropImageWrapper",
    "imageAttachWrapper",
    "avatar"
  ]
  static values = { maxSize: Number }

  selectedImage(event) {
    event.preventDefault()

    if (this.inputTarget && this.inputTarget.files[0]) {
      let size = this.inputTarget.files[0].size

      if (size > this.maxSizeValue) {
        alert("Maximum file size exceeds 10 mb")
        return
      } else if (size < this.maxSizeValue) {
        this.setSrc()
      }
    }
  }

  setSrc() {
    let input = this.inputTarget
    let output = this.imageCanvasTarget

    if (input.files && input.files[0]) {
      let that = this;
      let reader = new FileReader();

      reader.onload = () => {
        output.src = reader.result

        that.imageAttachWrapperTarget.classList.add("hidden")
        that.cropImageWrapperTarget.classList.toggle("hidden")

        that.setPreview(output)
      }
      reader.readAsDataURL(input.files[0]);
    }
  }

  setPreview(output) {
    this.cropper = new Cropper(output, {
      aspectRatio: 1 / 1,
      minCropBoxHeight: 100,
      minCropBoxWidth: 100,
      viewMode: 2,
      zoomable: true
    })

    let that = this;

    this.imageCropButtonTarget.addEventListener('click', (event) => {
      event.preventDefault()

      let croppedCanvas = this.cropper.getCroppedCanvas();
      let roundedCanvas = getRoundedCanvas(croppedCanvas);
      let roundedImage = document.createElement('img');

      that.cropImageWrapperTarget.classList.add("hidden")

      roundedImage.src = roundedCanvas.toDataURL()
      this.avatarTarget.setAttribute("src", roundedImage.src)

      croppedCanvas.toBlob((blob) => {
        const file = new File([blob], "avatar.png", { type: "image/png" });
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        this.inputTarget.files = dataTransfer.files;
      });

      this.imageAttachWrapperTarget.classList.remove("hidden")

      that.cropper.destroy()
    })
  }

  dontCrop(event) {
    event.preventDefault()
    this.cropImageWrapperTarget.classList.toggle("hidden")
    this.imageAttachWrapperTarget.classList.toggle("hidden")
    this.cropper.destroy()
  }

}

function getRoundedCanvas(sourceCanvas) {
  var canvas = document.createElement('canvas');
  var context = canvas.getContext('2d');
  var width = sourceCanvas.width;
  var height = sourceCanvas.height;

  canvas.width = width;
  canvas.height = height;
  context.imageSmoothingEnabled = true;
  context.drawImage(sourceCanvas, 0, 0, width, height);
  context.globalCompositeOperation = 'destination-in';
  context.beginPath();
  context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
  context.fill();
  return canvas;
}