import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js";

// Connects to data-controller="invoices"
export default class extends Controller {
  static targets = [
    "billingPeriod", "startDate", "endDate", "trackedTimes", "costs",
    "proposalValue", "totalValue", "totalLabel", "percentageInput",
    "lineDescription", "fixedValueInput", "percentageAmount",
    "actualConsolidation", "costsWrapper", "trackedTimesWrapper",
    "trackedTimesSelect", "processes"
  ];
  static values = { projectId: Number, budget: Number };

  changeType(e) {
    const showDateValues = ["billable", "original_proposal", "percentage", "fixed_value"];

    // Hide everything by default
    this.proposalValueTarget.classList.add("hidden");
    this.percentageInputTarget.classList.add("hidden");
    this.fixedValueInputTarget.classList.add("hidden");
    this.lineDescriptionTarget.classList.add("hidden");
    this.actualConsolidationTarget.classList.add("hidden");

    if (this.hasBillingPeriodTarget) {
      if (e.target.value === "blank") {
        this.billingPeriodTarget.classList.add("hidden");
      } else if (showDateValues.includes(e.target.value)) {
        this.billingPeriodTarget.classList.remove("hidden");
      }
    }

    if (e.target.value === "original_proposal") {
      this.proposalValueTarget.classList.remove("hidden");
    }

    if (e.target.value === "percentage") {
      this.percentageInputTarget.classList.remove("hidden");
      this.lineDescriptionTarget.classList.remove("hidden");
    }

    if (e.target.value === "fixed_value") {
      this.fixedValueInputTarget.classList.remove("hidden");
      this.lineDescriptionTarget.classList.remove("hidden");
    }

    if (e.target.value === "billable") {
      this.actualConsolidationTarget.classList.remove("hidden");
    }
  }

  selectAllTimes() {
    this.trackedTimesTarget.querySelectorAll("input[type='checkbox']").forEach((checkbox) => {
      checkbox.checked = true;
    });
  }

  deselectAllTimes() {
    this.trackedTimesTarget.querySelectorAll("input[type='checkbox']").forEach((checkbox) => {
      checkbox.checked = false;
    });
  }

  changeStartDate(e) {
    if (e.target._flatpickr.selectedDates[0] > this.endDateTarget._flatpickr.selectedDates[0]) {
      this.endDateTarget._flatpickr.setDate(e.target._flatpickr.selectedDates[0]);
    }

    this.fetchTrackedTime();
    this.fetchCosts();
  }

  changeEndDate() {
    if (this.startDateTarget._flatpickr.selectedDates[0] > this.endDateTarget._flatpickr.selectedDates[0]) {
      this.startDateTarget._flatpickr.setDate(this.endDateTarget._flatpickr.selectedDates[0]);
    }

    if (this.hasTrackedTimesTarget) {
      this.fetchTrackedTime();
    }
    if (this.hasCostsTarget) {
      this.fetchCosts();
    }
  }

  changeProcesses(e) {
    if (this.hasTrackedTimesTarget) {
      this.fetchTrackedTime();
    }
    if (this.hasCostsTarget) {
      this.fetchCosts();
    }
  }

  updatePercentValue(e) {
    const budget = parseFloat(this.budgetValue);
    const percentage = parseFloat(e.target.value);

    let result = 0;

    if (budget && budget > 0 && percentage && percentage > 0) {
      result = budget * (percentage / 100).toFixed(2);
    }

    this.percentageAmountTarget.textContent = `$${result}`;
  }

  fetchTrackedTime() {
    const queryParams = new URLSearchParams({
      start_date: this.startDateTarget.value,
      end_date: this.endDateTarget.value,
      project_id: this.projectIdValue
    });

    if (this.hasProcessesTarget) {
      this.processesTarget.slim.getSelected().forEach((id) => {
        queryParams.append("project_procedure_ids[]", id);
      });
    }

    get("/tracked_times.json?" + queryParams.toString(), {
      responseKind: "json"
    }).then(response => {
      return response.response.json();
    })
      .then(data => {
        if (data.tracked_times.length > 0) {
          this.trackedTimesSelectTarget.classList.remove("hidden");
        } else {
          this.trackedTimesSelectTarget.classList.add("hidden");
        }

        this.trackedTimesTarget.innerHTML = this.trackedTimesTemplate(data.tracked_times, data.tracked_time_ids);
      })
  }

  fetchCosts() {
    const queryParams = new URLSearchParams({
      start_date: this.startDateTarget.value,
      end_date: this.endDateTarget.value,
      project_id: this.projectIdValue
    });

    if (this.hasProcessesTarget) {
      this.processesTarget.slim.getSelected().forEach((id) => {
        queryParams.append("project_procedure_ids[]", id);
      });
    }

    get("/cost_invoices.json?" + queryParams.toString(), {
      responseKind: "json"
    }).then(response => {
      return response.response.json();
    })
      .then(data => {
        this.costsTarget.innerHTML = this.costsTemplate(data.costs, data.cost_ids);
      })

  }

  trackedTimesTemplate(times, ids) {
    if (times.length === 0) {
      return "<p class='text-sm text-slate-600'>No tracked times included. Changed the dates above if required.</p>"
    } else {
      return times.map((time, index) => {
        return `
        <div class="bg-slate-50 rounded-lg border border-slate-200 p-2 my-2">
          <div class="flex items-center justify-between space-x-2">
            <input type="checkbox" name="invoice[tracked_time_ids][]" value="${ids[index]}" checked="checked" class="form-checkbox" />
            <p class="text-sm flex-1">${time.date} - ${time.user} (${time.role})</p>
            <p class="text-sm">${time.duration} (${time.amount})</p>
          </div>
          <p class="text-xs text-slate-500">${time.project_procedure_name}</p>
        </div >
      `
      }).join("");
    }
  }

  costsTemplate(costs, ids) {
    if (costs.length === 0) {
      return "<p class='text-sm text-slate-600'>No costs included. Changed the dates above if required.</p>"
    } else {
      return costs.map((cost, index) => {
        return `
        <div class="bg-slate-50 rounded-lg border border-slate-200 flex p-2 my-2 justify-between items-center space-x-2">
          <input type="checkbox" name="invoice[cost_ids][]" value="${ids[index]}" class="form-checkbox" />
          <p class="text-sm flex-1">${cost.formatted_issued_date} - ${cost.company_name} - ${cost.name}</p>
          <p class="text-sm">${cost.amount}</p>
        </div>
      `
      }).join("");
    }
  }
}
