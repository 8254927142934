import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["accountUserField", "form", "errorSlot"]

  connect() {
    this.validateFields()
    this.formTarget.addEventListener('submit', this.submitForm.bind(this))
  }

  validateFields() {
    const isValid = this.accountUserFieldTargets.some(field => field.value !== "")
    
    if (!isValid) {
      this.showError()
    } else {
      this.hideError()
    }
  }

  allowSubmit() {
    return this.accountUserFieldTargets.some(field => field.value !== "")
  }

  showError() {
    if (!this.errorElement) {
      this.errorElement = document.createElement("div")
      this.errorElement.classList.add("alert", "alert-error", "mt-4")
      this.errorElement.textContent = "At least one staff member must be added to one of the project roles to create this project."
      this.errorSlotTarget.appendChild(this.errorElement)
    }
    this.element.classList.add("is-invalid")
  }

  hideError() {
    if (this.errorElement) {
      this.errorElement.remove()
      this.errorElement = null
    }
    this.element.classList.remove("is-invalid")
  }

  fieldChanged() {
    this.validateFields()
  }

  submitForm(event) {
    if (!this.allowSubmit()) {
      event.preventDefault()
      this.validateFields()
    }
  }
}
