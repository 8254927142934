import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  close(e) {
    e.preventDefault();

    // Remove the modal element so it doesn't blanket the screen
    this.element.remove();
  }
}
