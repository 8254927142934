import { useState, useEffect, useMemo } from 'react';
import Month from './Month';
import FlatpickrDate from './FlatPickrDate';

interface PlanProps {
  projectId: string;
  originPage: string;
}

interface MonthData {
  name: string;
  month: string;
  index: number;
  revenue: number;
  estimatedRevenue: number;
  revenueHeight: number;
  estimatedRevenueHeight: number;
  plannedInvoice: number;
  plannedInvoicePercent: number;
  locked: boolean;
}

const Plan = ({ projectId, originPage }: PlanProps) => {
  const [projectName, setProjectName] = useState('');
  const [projectNumber, setProjectNumber] = useState('');
  const [updateProjectDates, setUpdateProjectDates] = useState(false);
  const [budget, setBudget] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [estimatedDeposit, setEstimatedDeposit] = useState(0);
  const [depositInput, setDepositInput] = useState(estimatedDeposit.toString());
  const [scheduleType, setScheduleType] = useState('');
  const [months, setMonths] = useState<MonthData[]>([]);
  const [currencyCode, setCurrencyCode] = useState('');
  const [error, setError] = useState('');

  const formattedBudget = useMemo(() => {
    return budget.toLocaleString('en-AU', {
      style: 'currency',
      currency: 'AUD'
    });
  }, [budget]);

  // const largestMonthRevenue = useMemo(() => {
  //   return months.reduce((max, month) => {
  //     const totalRevenue = month.revenue + month.estimatedRevenue;
  //     return totalRevenue > max.revenue ? { revenue: totalRevenue } : max;
  //   }, { revenue: 0 });
  // }, [months]);

  const toggleMonthLock = (index: number) => {
    setMonths(prevMonths => {
      const newMonths = [...prevMonths];
      newMonths[index].locked = !newMonths[index].locked;
      return newMonths;
    });
  };

  const updateMonth = ({ index, plannedInvoice, plannedInvoicePercent }: {
    index: number;
    plannedInvoice?: number;
    plannedInvoicePercent?: number;
  }) => {
    setMonths(prevMonths => {
      const newMonths = [...prevMonths];
      if (plannedInvoicePercent !== undefined) {
        const newPlannedInvoice = Number(((plannedInvoicePercent / 100) * budget).toFixed(2));
        newMonths[index].plannedInvoicePercent = Number(plannedInvoicePercent.toFixed(2));
        newMonths[index].plannedInvoice = newPlannedInvoice;
      } else if (plannedInvoice !== undefined) {
        newMonths[index].plannedInvoice = Number(plannedInvoice.toFixed(2));
        newMonths[index].plannedInvoicePercent = Number(((plannedInvoice / budget * 100)).toFixed(2));
      }
      return newMonths;
    });

    recalculateMonths();
  };

  const calculateLockedAmount = (months: MonthData[]) => {
    return months.reduce((sum, month) => month.locked ? sum + month.plannedInvoice : sum, 0);
  };

  const createMonthlyInvoice = (month: MonthData, index: number, params: {
    deposit: number,
    monthlyAmount: number,
    totalBudget: number
  }) => {
    const { deposit, monthlyAmount, totalBudget } = params;

    if (month.locked) return month;

    if (index === 0 && deposit > 0) {
      return {
        ...month,
        plannedInvoice: deposit,
        plannedInvoicePercent: Number(((deposit / totalBudget) * 100).toFixed(2))
      };
    }

    return {
      ...month,
      plannedInvoice: Number(monthlyAmount.toFixed(2)),
      plannedInvoicePercent: Number(((monthlyAmount / totalBudget) * 100).toFixed(2))
    };
  };

  const createFinalPaymentInvoice = (month: MonthData, index: number, params: {
    deposit: number,
    remainder: number,
    totalBudget: number,
    monthsLength: number
  }) => {
    const { deposit, remainder, totalBudget, monthsLength } = params;

    if (month.locked) return month;

    if (index === 0 && deposit > 0) {
      return {
        ...month,
        plannedInvoice: deposit,
        plannedInvoicePercent: Number(((deposit / totalBudget) * 100).toFixed(2))
      };
    }

    const isFinalMonth = index === monthsLength - 1;
    const amount = isFinalMonth ? remainder : 0;

    return {
      ...month,
      plannedInvoice: Number(amount.toFixed(2)),
      plannedInvoicePercent: Number((amount / totalBudget * 100).toFixed(2))
    };
  };

  const recalculateMonths = () => {
    setMonths(currentMonths => {
      if (!currentMonths.length) return currentMonths;

      const totalBudget = budget;
      const deposit = estimatedDeposit;
      const unlockedMonths = currentMonths.filter(month => !month.locked);
      const lockedAmount = calculateLockedAmount(currentMonths);
      const remainingBudget = totalBudget - deposit - lockedAmount;

      if (scheduleType === 'monthly') {
        const monthsToDistribute = deposit > 0 ? unlockedMonths.length - 1 : unlockedMonths.length;
        const monthlyAmount = monthsToDistribute > 0 ? remainingBudget / monthsToDistribute : 0;

        return currentMonths.map((month, index) =>
          createMonthlyInvoice(month, index, {
            deposit,
            monthlyAmount,
            totalBudget
          })
        );
      }

      if (scheduleType === 'payment_on_delivery') {
        return currentMonths.map((month, index) =>
          createFinalPaymentInvoice(month, index, {
            deposit,
            remainder: remainingBudget,
            totalBudget,
            monthsLength: currentMonths.length
          })
        );
      }

      return currentMonths;
    });
  };

  const fetchProjectPlan = async () => {
    try {
      const response = await fetch(`/projects/${projectId}/plan.json?start_date=${startDate}&end_date=${endDate}`);
      const data = await response.json();

      setCurrencyCode(data.currency_code);
      setProjectName(data.name);
      setProjectNumber(data.project_number);
      setBudget(data.total_budget_cents / 100);
      setEstimatedDeposit(data.estimated_deposit_cents / 100);
      setStartDate(data.start_date);
      setEndDate(data.end_date);
      setScheduleType(data.invoicing_structure);
      setMonths(data.months.map((month: any, index: number) => ({
        name: month.name,
        month: month.month,
        index: index,
        revenue: month.revenue || 0,
        estimatedRevenue: month.estimated_revenue || 0,
        revenueHeight: month.revenue_height,
        estimatedRevenueHeight: month.estimated_revenue_height,
        plannedInvoice: 0,
        plannedInvoicePercent: 0,
        locked: false
      })));

      recalculateMonths();
    } catch (error) {
      console.error('Error fetching project plan:', error);
    }
  };

  const redirectUser = () => {
    if (originPage === "schedules") {
      window.location.href = `/projects/${projectId}/scheduled_invoices`;
    } else {
      window.location.href = `/projects/${projectId}`;
    }
  };

  const skipPlan = async () => {
    try {
      await fetch(`/projects/${projectId}/save_plan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
        },
        body: JSON.stringify({ skip_planning: true })
      });
      redirectUser();
    } catch (error) {
      console.error('Error skipping plan:', error);
    }
  };

  const savePlan = async () => {
    try {
      const response = await fetch(`/projects/${projectId}/save_plan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
        },
        body: JSON.stringify({
          start_date: startDate,
          end_date: endDate,
          invoicing_structure: scheduleType,
          planned: true,
          update_project_dates: updateProjectDates,
          months: months,
          origin: originPage
        })
      });

      if (response.ok) {
        redirectUser();
      } else {
        const data = await response.json();
        setError(data.errors.join(', '));
      }
    } catch (error) {
      setError('An error occurred while saving the plan.');
      console.error('Error saving plan:', error);
    }
  };

  useEffect(() => {
    fetchProjectPlan();
  }, []);

  useEffect(() => {
    recalculateMonths();
  }, [scheduleType, estimatedDeposit]);

  useEffect(() => {
    if (startDate || endDate) {
      fetchProjectPlan();
    }
  }, [startDate, endDate]);

  return (
    <div className="modal">
      <div className="modal-head">
        <h2 className="h4 mb-0">
          Project Plan - {projectNumber} {projectName}
        </h2>
        <p className="modal-helper">Quickly add revenue projections & break down the project budget per phase (if required).</p>
      </div>
      <div className="modal-body">
        <div className="bg-slate-100 p-4 grid lg:grid-cols-6 gap-4 rounded-lg">
          <div className="col-span-2">
            <div className="flex space-x-4">
              <div className="form-group">
                <label htmlFor="start_date">Start Date</label>
                <FlatpickrDate value={startDate} onChange={setStartDate} id="start_date" />
              </div>
              <div className="form-group">
                <label htmlFor="end_date">End Date</label>
                <FlatpickrDate value={endDate} onChange={setEndDate} id="end_date" />
              </div>
            </div>
            <div>
              <input
                type="checkbox"
                checked={updateProjectDates}
                onChange={(e) => setUpdateProjectDates(e.target.checked)}
                className="form-checkbox mr-2 mt-0"
                id="update_project_dates"
              />
              <label htmlFor="update_project_dates" className="inline-block mb-0">
                Update the associated project dates to match the above?
              </label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="schedule_type">Billing Type</label>
            <select
              id="schedule_type"
              value={scheduleType}
              onChange={(e) => setScheduleType(e.target.value)}
              className="form-control"
              required
            >
              <option value="">Select schedule type</option>
              <option value="monthly">Monthly</option>
              <option value="payment_on_delivery">Payment on completion</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="deposit_amount">Deposit Amount</label>
            <input
              type="number"
              id="deposit_amount"
              value={depositInput}
              onChange={(e) => {
                setDepositInput(e.target.value);
                setEstimatedDeposit(e.target.value === '' ? 0 : Number(e.target.value));
              }}
              className="form-control"
              step="0.01"
              min="0"
              placeholder="Enter an optional deposit amount"
            />
          </div>
          <div></div>
          <div className="bg-white border-slate-200 rounded-lg text-lg flex flex-col items-end justify-center p-4">
            <div className="h5 uppercase text-slate-400 text-xs">Remaining Project Budget</div>
            <span>{formattedBudget}</span>
          </div>
        </div>

        <div className="mt-4">
          <h5 className="h5 mt-4 mb-2">Billing Plan</h5>
          {months.length > 0 ? (
            <div className={`grid grid-cols-${months.length > 4 ? 4 : months.length} gap-y-4`}>
              {months.map((month, index) => (
                <Month
                  key={month.month}
                  month={month}
                  index={index}
                  total={months.length}
                  currencyCode={currencyCode}
                  onToggleLock={() => toggleMonthLock(index)}
                  onUpdateMonth={updateMonth}
                />
              ))}
            </div>
          ) : (
            <div className="alert alert-info text-center">
              <p>No invoice plan has been set for this project yet. Choose a billing type above to start.</p>
            </div>
          )}
        </div>

        {error && (
          <div className="alert alert-warning mt-4">
            {error}
          </div>
        )}
      </div>

      <div className="modal-footer">
        <div></div>
        <div className="modal-footer__right">
          <button className="btn btn-gray mr-4" onClick={skipPlan}>
            Skip Project Plan
          </button>
          <button className="btn btn-primary" onClick={savePlan}>
            Save & View Project
          </button>
        </div>
      </div>
    </div>
  );
};

export default Plan;
