import { Controller } from "@hotwired/stimulus"
import { Draggable } from '@fullcalendar/interaction';

export default class extends Controller {
  static targets = ["booking"];

  connect() {
    this.bookingTargets.forEach(booking => {
      new Draggable(booking, {
        eventData: {
          title: booking.dataset.title || "None Set",
          duration: booking.dataset.duration
        }
      });
    });
  }
}