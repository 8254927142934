import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin from '@fullcalendar/interaction';
import { get, post } from '@rails/request.js'

// Connects to data-controller="calendar"
export default class extends Controller {
  static targets = ["calendar", "booking"];
  static values = {
    planner: Boolean,
    personal: Boolean,
    resourceId: Number,
    name: String,
    start: String,
    end: String,
    clientName: String,
    projectName: String,
    projectId: Number,
    range: String,
    saturdayShown: Boolean,
    sundayShown: Boolean
  };

  connect() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.resourceId = this.hasResourceIdValue ? this.resourceIdValue : urlParams.get('available_resource_id');
    this.bookingId = urlParams.get('booking_id');
    this.projectId = urlParams.get('project_id');
    this.teamId = urlParams.get('team');
    this.range = urlParams.get('range');
    const paramResourceTypeIds = urlParams.getAll('resource_type_ids[]');
    this.resourceTypeIds = new URLSearchParams(paramResourceTypeIds.map(id => ['resource_type_ids', id])).toString();
    this.name = urlParams.get('name');
    this.bookingType = urlParams.get('booking_type');
    let resourcesUrl = "/available_resources.json?";
    let eventsUrl = "/events.json?"
    let holidaysUrl = "/public_holidays.json?"
    let unavailableUrl = "/unavailable_resources.json?"

    if (this.resourceId) {
      resourcesUrl = `${resourcesUrl}available_resource_id=${this.resourceId}&`;
      eventsUrl = `${eventsUrl}available_resource_id=${this.resourceId}&`;
      holidaysUrl = `${holidaysUrl}available_resource_id=${this.resourceId}&`;
      unavailableUrl = `${unavailableUrl}available_resource_id=${this.resourceId}&`;
    }

    if (this.resourceTypeIds) {
      resourcesUrl = `${resourcesUrl}${this.resourceTypeIds}&`;
      eventsUrl = `${eventsUrl}${this.resourceTypeIds}&`;
      holidaysUrl = `${holidaysUrl}${this.resourceTypeIds}&`;
      unavailableUrl = `${unavailableUrl}${this.resourceTypeIds}&`;
    }

    if (this.bookingType) {
      resourcesUrl = `${resourcesUrl}booking_type=${this.bookingType}&`;
      eventsUrl = `${eventsUrl}booking_type=${this.bookingType}&`;
      holidaysUrl = `${holidaysUrl}booking_type=${this.bookingType}&`;
      unavailableUrl = `${unavailableUrl}booking_type=${this.bookingType}&`;
    }

    if (this.name) {
      resourcesUrl = `${resourcesUrl}name=${this.name}&`;
      eventsUrl = `${eventsUrl}name=${this.name}&`;
      holidaysUrl = `${holidaysUrl}name=${this.name}&`;
      unavailableUrl = `${unavailableUrl}name=${this.name}&`;
    }

    if (this.teamId) {
      resourcesUrl = `${resourcesUrl}team=${this.teamId}&`;
      eventsUrl = `${eventsUrl}team=${this.teamId}&`;
      holidaysUrl = `${holidaysUrl}team=${this.teamId}&`;
      unavailableUrl = `${unavailableUrl}team=${this.teamId}&`;
    }

    if (this.projectId) {
      eventsUrl = `${eventsUrl}project_id=${this.projectId}&`;
    }

    if (this.hasPlannerValue) {
      eventsUrl = `${eventsUrl}planner=true`;
    }

    this.calendar = new Calendar(this.calendarTarget, {
      droppable: this.hasPlannerValue,
      editable: this.hasPlannerValue,
      slotEventOverlap: true,
      height: "100%",
      resourceOrder: "title",
      dateClick: e => this.dateClick(e),
      eventClick: this.eventClick,
      eventMouseEnter: e => this.eventMouseEnter(e),
      eventMouseLeave: this.eventMouseLeave,
      eventContent: args => this.eventContent(args),
      eventClassNames: args => this.eventClassNames(args),
      resourceLabelContent: args => this.resourceLabelContent(args),
      drop: this.dropExternalEvent,
      eventDrop: this.dropEvent,
      eventResize: this.resizeEvent,
      datesSet: args => this.handleRangeChange(args),
      schedulerLicenseKey: '0032247324-fcs-1550108260',
      plugins: [resourceTimelinePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin],
      headerToolbar: {
        left: this.hasPersonalValue ? '' : 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth',
        center: this.hasPersonalValue ? '' : 'title',
        right: 'prev,next today'
      },
      views: this.viewLayouts(),
      initialView: this.initialView(),
      resources: resourcesUrl,
      eventSources: [
        {
          url: eventsUrl
        },
        {
          url: holidaysUrl,
          color: "rgb(224 231 255)",
          textColor: "rgb(99 102 241)",
          borderColor: "rgb(67 56 202)",
        },
        {
          url: unavailableUrl,
          color: "rgb(255 224 224)",
          textColor: "rgb(255 99 99)",
          borderColor: "rgb(202 56 56)",
        }
      ],
      slotMinWidth: 100,
      initialDate: (this.startValue === '') ? null : this.startValue,
      eventsSet: events => this.eventsSet(events)
    });

    this.calendar.render();
  }

  disconnect() {
    this.calendar.destroy();
  }

  initialView() {
    if (this.hasPersonalValue) {
      return 'timeGridDay';
    }

    return (this.range && this.range.length) ? this.range : 'resourceTimelineMonth';
  }

  eventsSet(events) {
    if (events.length && (this.projectId || this.bookingId)) {
      this.scrollToHighlightEvent();
    }
  }

  scrollToHighlightEvent() {
    window.setTimeout(function () {
      if (document.getElementsByClassName('event-highlight').length > 0) {
        document.getElementsByClassName('event-highlight')[0].scrollIntoView({ block: "center", inline: "center" });
      }
    }, 1000);
  }

  handleRangeChange(e) {
    if (!this.calendar) return;
    let range = document.getElementById("range");

    if (!range) return;

    document.getElementById("range").value = e.view.type;

    const today = new Date();
    const monthMilliseconds = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) - Date.UTC(today.getFullYear(), today.getMonth(), 1);
    if (!this.projectId || this.hasPlannerValue) {
      if (e.view.type === "resourceTimelineMonth" && e.view.currentStart.getTime() < today.getTime() && e.view.currentEnd.getTime() > today.getTime()) {
        this.calendar.scrollToTime(monthMilliseconds);
      } else {
        this.calendar.scrollToTime(0);
      }
    }
  }

  viewLayouts() {
    let hiddenDays = [];

    if (this.hasSaturdayShownValue && !this.saturdayShownValue) {
      hiddenDays.push(6);
    }
    if (this.hasSundayShownValue && !this.sundayShownValue) {
      hiddenDays.push(0);
    }

    if (this.hasPersonalValue) {
      // Scroll to current time
      return {
        timeGridDay: {
          dayHeaderFormat: { weekday: 'long', month: 'numeric', day: 'numeric', omitCommas: true },
          scrollTime: "09:00:00",
        },
      }
    } else {
      const today = new Date();
      const monthMilliseconds = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) - Date.UTC(today.getFullYear(), today.getMonth(), 1);

      return {
        resourceTimelineMonth: {
          titleFormat: { year: 'numeric', month: 'long', day: 'numeric', weekday: "short" },
          slotLabelFormat: { weekday: "short", day: "numeric" },
          scrollTime: monthMilliseconds,
          resourceAreaWidth: 220,
          hiddenDays: hiddenDays,
          scrollTimeReset: false
        },
        resourceTimelineWeek: {
          titleFormat: { year: 'numeric', month: 'long', day: 'numeric' },
          slotMinWidth: 35,
          hiddenDays: hiddenDays,
          slotLabelInterval: { hours: 4 }
        },
        resourceTimelineDay: {
          titleFormat: { year: 'numeric', month: 'long', day: 'numeric' },
          slotMinWidth: 30,
          slotLabelInterval: { hours: 1 }
        }
      }
    }
  }

  async dateClick(info) {
    // This allows a user to click on a date and create a new event when a user is booking an invididual booking from a project.
    if (this.bookingId) {
      const startString = `${info.date.getFullYear()}-${info.date.getMonth() + 1}-${info.date.getDate()}`;

      this.calendar.addEvent(
        {
          id: 0,
          start: info.date,
          end: info.date,
          rendering: 'background',
          color: 'rgb(239 246 255/var(--tw-bg-opacity))',
          role: this.nameValue,
          name: this.nameValue,
          resourceId: info.resource.id,
          client_name: this.clientNameValue,
          project: this.projectNameValue,
        },
      );

      const url = `/calendar/new?booking_id=${this.bookingId}&${this.resourceTypeIds}&start_date=${startString}&resource_id=${info.resource.id}`;
      await get(url, { responseKind: "turbo-stream" });
    }
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  eventMouseEnter(info) {
    if (info.event.extendedProps.service == "xero") return;

    const lastResource = this.calendar.getTopLevelResources().sort((a, b) => a.title.localeCompare(b.title)).reverse()[0];
    const viewStart = info.view.activeStart;
    const eventStart = info.event.start;
    const daysInMonth = new Date(viewStart.getFullYear(), viewStart.getMonth(), 0).getDate();
    const view = info.view.type;
    let endOfWeekBuffer = new Date(info.view.activeEnd);
    endOfWeekBuffer.setDate(endOfWeekBuffer.getDate() - 1);

    if (view === "resourceTimelineMonth" && (eventStart.getDate() > (daysInMonth - 4)) && eventStart.getMonth() === viewStart.getMonth()) {
      info.el.classList.add('event__hover', 'event__hover--left');
    } else if (view === "resourceTimelineWeek" && eventStart > endOfWeekBuffer) {
      info.el.classList.add('event__hover', 'event__hover--left');
    } else {
      info.el.classList.add('event__hover');
    }

    if (this.calendar.getTopLevelResources().length > 1 && info.event._def.resourceIds.includes(lastResource.id)) {
      info.el.classList.add('event__hover--bottom');
    }
  }

  eventMouseLeave(info) {
    info.el.classList.remove('event__hover', 'event__hover--left', 'event__hover--bottom');
  }

  resourceLabelContent({ fieldValue, resource }) {
    const html = `
      <div>
        <a href="/available_resources/${resource.id}" target="_blank">${fieldValue}</a>
        <div>${resource.extendedProps.resource_types}</div>
      </div>
    `;

    return {
      html
    }
  }

  eventContent({ event }) {
    if (!event.end || !event.start) {
      let icon = ``;

      if (event.extendedProps.holiday) {
        icon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-indigo-700 w-4 h-4 flex-shrink-0">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.87c1.355 0 2.697.055 4.024.165C17.155 8.51 18 9.473 18 10.608v2.513m-3-4.87v-1.5m-6 1.5v-1.5m12 9.75l-1.5.75a3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0L3 16.5m15-3.38a48.474 48.474 0 00-6-.37c-2.032 0-4.034.125-6 .37m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.17c0 .62-.504 1.124-1.125 1.124H4.125A1.125 1.125 0 013 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 016 13.12M12.265 3.11a.375.375 0 11-.53 0L12 2.845l.265.265zm-3 0a.375.375 0 11-.53 0L9 2.845l.265.265zm6 0a.375.375 0 11-.53 0L15 2.845l.265.265z" />
        </svg>
        `;
      }

      return {
        html: `
          <div class="event">
            <div class="event__title">${icon}<span>${event.title}</span></div>
          </div>
        `
      };
    } else if (event.extendedProps.service == "xero") {
      // This booking is leave
      let icon = ``;

      if (event.extendedProps.service == "xero") {
        icon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-indigo-700 w-4 h-4 flex-shrink-0">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.87c1.355 0 2.697.055 4.024.165C17.155 8.51 18 9.473 18 10.608v2.513m-3-4.87v-1.5m-6 1.5v-1.5m12 9.75l-1.5.75a3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0L3 16.5m15-3.38a48.474 48.474 0 00-6-.37c-2.032 0-4.034.125-6 .37m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.17c0 .62-.504 1.124-1.125 1.124H4.125A1.125 1.125 0 013 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 016 13.12M12.265 3.11a.375.375 0 11-.53 0L12 2.845l.265.265zm-3 0a.375.375 0 11-.53 0L9 2.845l.265.265zm6 0a.375.375 0 11-.53 0L15 2.845l.265.265z" />
        </svg>
        `;
      }

      return {
        html: `
          <div class="event">
            <div class="event__title">${icon}<span>Leave</span></div>
          </div>
        `
      };

    } else {
      let url;
      let title;
      let subtitle;
      let note;
      let tags;
      let icon = '';

      if (event.extendedProps.service == "drum") {
        url = `/projects/${event.extendedProps.project_id}`;
      } else {
        url = event.extendedProps.third_party_url;
      }

      if (event.extendedProps.service == "google") {
        title = `
          <div class="flex items-center space-x-1">
            <svg class="h-3 w-3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"><defs><path id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/></defs><clipPath id="b"><use xlink:href="#a" overflow="visible"/></clipPath><path clip-path="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z"/><path clip-path="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/><path clip-path="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/><path clip-path="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z"/></svg>
            <span>${event.extendedProps.name}</span>
          </div>
        `;
        subtitle = `${event.extendedProps.status} - ${event.extendedProps.show_as}`;
      } else if (event.extendedProps.service == "microsoft") {
        title = `
          <div class="flex items-center space-x-1">
            <svg class="h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="64" height="64"><path d="M0 0h15.206v15.206H0z" fill="#f25022"/><path d="M16.794 0H32v15.206H16.794z" fill="#7fba00"/><path d="M0 16.794h15.206V32H0z" fill="#00a4ef"/><path d="M16.794 16.794H32V32H16.794z" fill="#ffb900"/></svg>
            <span>${event.extendedProps.name}</span>
          </div>
        `;
        subtitle = `${event.extendedProps.status} - ${event.extendedProps.show_as}`;
      } else {
        if (event.extendedProps.procedure_status === "completed") {
          icon = `<div class="h-3 w-3 rounded-full mr-2 flex-shrink-0 flex items-center p-0.5 justify-center bg-blue-800 text-white hover:bg-blue-900 hover:text-white"><svg xmlns="http://www.w3.org/2000/svg" title="Completed process" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
          </svg></div>`
        } else if (event.extendedProps.procedure_status === "in_progress") {
          icon = `<div class="h-3 w-3 rounded-full mr-2 flex-shrink-0 flex items-center p-0.5 justify-center bg-green-500 text-white hover:bg-green-600 hover:text-white"><svg xmlns="http://www.w3.org/2000/svg" title="In progress process" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"></path>
          </svg></div>`;
        } else {
          icon = ""
        };
        const tentativeIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 text-slate-500">
          <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>`;
        const confirmedIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 text-blue-500">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>`;
        const timeIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 text-slate-500">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>`;
        const companyIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 text-slate-500">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
        </svg>`;
        const statusIcon = event.extendedProps.status === "Tentative" ? tentativeIcon : confirmedIcon;
        title = `${event.extendedProps.project_number ? event.extendedProps.project_number : ''} ${event.extendedProps.projectProcedure ? `${event.extendedProps.projectProcedure} | ` : ''}${event.extendedProps.name} - ${event.extendedProps.project}`;
        subtitle = `<div class="flex items-center space-x-2">
          <p class="flex items-center space-x-1">${timeIcon}<span>${event.extendedProps.actual_hours} hrs resourced</span></p>
          <p class="flex items-center space-x-1">${companyIcon}<span>${event.extendedProps.client_name ? `${event.extendedProps.client_name}` : ''}</span></p>
          <p class="flex items-center space-x-1">${statusIcon}<span>${event.extendedProps.status}</span></p>
        </div>`;
      }

      if (event.extendedProps.note) {
        note = `<div class="event__note">${event.extendedProps.note}</div>`;
      }

      if (event.extendedProps.tags) {
        const tagEls = event.extendedProps.tags.map(tag => `<span class="event__tag">${tag.name}</span>`).join('');
        tags = `<div class="event__tags">${tagEls}</div>`;
      }

      let html = `
        <div class="event" id="event_${event.id}">
          <div class="event__title">
            <a href="${url}" data-turbo="false" target="_blank">${icon}${title}</a>
            ${this.hasPlannerValue && event.extendedProps.service === "drum" ? `<div class="event__edit ml-2"><a href="/calendar/${event.id}/edit?planner=true" data-turbo-stream="true">Edit Event</a></div>` : ''}
          </div>
          <div class="event__client">${subtitle}</div>
          <div class="event__dates">
            <span><strong>Start: </strong> ${event.start.toLocaleString('en-GB', { timeZone: event.extendedProps.time_zone })}</span>
            ${this.hasPersonalValue ? '<br/>' : ''}
            <span><strong>End: </strong> ${event.end.toLocaleString('en-GB', { timeZone: event.extendedProps.time_zone })}</span>
          </div>
          ${tags ? tags : ''}
          ${note ? note : ''}
          ${event.extendedProps.additional_fields ? `<div class="event__additional">${event.extendedProps.additional_fields}</div>` : ''}
        </div>
      `;

      return { html }
    }
  }

  eventClassNames({ event }) {
    let classes = [];

    if (event.extendedProps.service == "xero") {
      return ["event-leave"];
    }

    if (this.bookingId && this.bookingId == event.id) {
      classes.push("event-highlight");
    }
    if (!this.hasPlannerValue && this.projectId && this.projectId == event.extendedProps.project_id) {
      classes.push("event-highlight");
    }
    if (!this.hasPlannerValue && this.projectId && this.projectId !== event.extendedProps.project_id) {
      return ["event-normal"].concat(classes);
    }
    if (event.extendedProps.procedure_status == "completed") {
      return ["event-complete"].concat(classes);
    } else if (event.extendedProps.procedure_status === "in_progress") {
      return ["event-in-progress"].concat(classes);
    } else if (event.extendedProps.status === "Tentative") {
      return ["event-tentative"].concat(classes);
    }

    return classes;
  }

  async dropExternalEvent(info) {
    const startString = `${info.date.getFullYear()}-${info.date.getMonth() + 1}-${info.date.getDate()}`;

    const url = `/calendar/${info.draggedEl.dataset.id}/edit?planner=true&${info.draggedEl.dataset.resourceTypeIds}&start_date=${startString}&resource_id=${info.resource._resource.id}&dropped=true&apply_defaults=true`;
    await get(url, { responseKind: "turbo-stream" });
  }

  async dropEvent(info) {
    const start = info.event.start;
    start.setHours(start.getHours() == 0 ? 9 : start.getHours());
    const end = info.event.end;

    let url = `/calendar/${info.event.id}/edit?planner=true&${info.event.extendedProps.resourceTypeIds}&start_date=${start.toISOString()}&end_date=${end.toISOString()}&dropped=true`;

    if (info.newResource) {
      url += `&resource_id=${info.newResource.id}`;
    }

    await get(url, { responseKind: "turbo-stream" });
  }

  async resizeEvent(info) {
    const start = info.event.start;
    start.setHours(start.getHours() == 0 ? 9 : start.getHours());
    const end = info.event.end;

    let url = `/calendar/${info.event.id}/edit?planner=true&start_date=${start.toISOString()}&end_date=${end.toISOString()}&resized=true`;

    await get(url, { responseKind: "turbo-stream" });
  }

  async updateEventsSource(e) {
    e.preventDefault();

    this.calendar.removeAllEvents();
    this.calendar.refetchEvents();
  }

  async saveChange(e) {
    e.preventDefault();

    const form = e.target.closest('form');
    const formData = new FormData(form);
    const url = form.action;

    const response = await post(url, { responseKind: "turbo-stream", body: formData });

    if (response.ok) {
      this.calendar.removeAllEvents();
      this.calendar.refetchEvents();
    }
  }
}
