import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "target"]
  static values = {
    wrapperSelector: String
  }

  add(event) {
    event.preventDefault()
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.targetTarget.insertAdjacentHTML('beforeend', content)
  }

  remove(event) {
    event.preventDefault()
    const wrapper = event.target.closest(this.wrapperSelectorValue)
    if (wrapper) {
      wrapper.remove()
    }
  }

  removeAll(event) {
    event.preventDefault();

    const wrappers = this.element.querySelectorAll(this.wrapperSelectorValue)
    wrappers.forEach(wrapper => wrapper.remove())
  }
}