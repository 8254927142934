import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "container", "ids"]

  connect() {
    this.updateContainer()
  }

  toggle() {
    this.updateContainer()
    this.updateIds()
  }

  toggleAll(event) {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = event.target.checked
    })
    this.updateContainer()
    this.updateIds()
  }

  updateIds() {
    const selectedIds = this.getSelectedIds()
    this.idsTarget.value = selectedIds.join(',')
  }

  updateContainer() {
    const hasSelected = this.checkboxTargets.some(checkbox => checkbox.checked)
    this.containerTarget.classList.toggle("hidden", !hasSelected)
  }

  getSelectedIds() {
    return this.checkboxTargets
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value)
  }
}
