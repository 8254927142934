import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="tracked-time-form"
export default class extends Controller {
  static targets = ['displayTimeCheckbox', 'displayTime']

  connect() {
    if (this.displayTimeCheckboxTarget.checked) {
      this.displayTimeTarget.classList.remove('hidden');
    }
  }

  toggleDisplayTime() {
    if (this.displayTimeCheckboxTarget.checked) {
      this.displayTimeTarget.classList.remove('hidden');
    } else {
      this.displayTimeTarget.classList.add('hidden');
    }
  }
}
