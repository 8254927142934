import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dashboard-process"
export default class extends Controller {
  static targets = ["tasks"]

  toggleTasks() {
    this.tasksTarget.classList.toggle("hidden");
  }
}
