import { useEffect, useRef } from 'react';
import flatpickr from 'flatpickr';
import { Instance } from 'flatpickr/dist/types/instance';

interface FlatpickrDateProps {
  value: string | null;
  onChange: (date: string) => void;
  id: string;
}

const FlatpickrDate: React.FC<FlatpickrDateProps> = ({ value, onChange, id }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const flatpickrInstance = useRef<Instance | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      flatpickrInstance.current = flatpickr(inputRef.current, {
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: "D J F Y",
        allowInput: true,
        defaultDate: value || null,
        onChange: (selectedDates: Date[], dateStr: string) => {
          onChange(dateStr);
        },
        static: true // This ensures the original input is hidden
      });
    }

    return () => {
      flatpickrInstance.current?.destroy();
    };
  }, []);

  useEffect(() => {
    if (flatpickrInstance.current && value) {
      flatpickrInstance.current.setDate(value, true);
    }
  }, [value]);

  return (
    <div className="flatpickr-date">
      <input
        type="text"
        value={value || ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        className="form-control"
        ref={inputRef}
        id={id}
        style={{ display: 'none' }} // Hide the original input
      />
    </div>
  );
};

export default FlatpickrDate;
