import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = [
    "resourcesLink",
    "costsLink",
    "overheadsLink",
    "resources",
    "costs",
    "overheads",
    "resourceType",
    "costType",
    "overheadType",
    "procedures"
  ]
  static values = { finalised: Boolean }

  connect() {
    const addLoader = this.addLoader.bind(this);
    const dropResourceType = this.dropResourceType.bind(this);
    const dropCost = this.dropCost.bind(this);
    const dropOverhead = this.dropOverhead.bind(this);

    if (!this.finalisedValue) {
      new Sortable(this.resourcesTarget, {
        animation: 150,
        group: {
          name: "shared",
          pull: "clone",
          put: false
        },
        onEnd(event) {
          if (event.to.dataset.controller == 'budget-procedure') {
            addLoader(event);
            dropResourceType(event.clone.dataset.id, event.to.dataset.id, event.newIndex + 1);
          }
        }
      });

      new Sortable(this.costsTarget, {
        animation: 150,
        group: {
          name: "shared",
          pull: "clone",
          put: false
        },
        onEnd(event) {
          if (event.to.dataset.controller == 'budget-procedure') {
            addLoader(event);
            dropCost(event.clone.dataset.id, event.to.dataset.id, event.newIndex + 1);
          }
        }
      });

      new Sortable(this.overheadsTarget, {
        animation: 150,
        group: {
          name: "shared",
          pull: "clone",
          put: false
        },
        onEnd(event) {
          if (event.to.dataset.controller == 'budget-procedure') {
            addLoader(event);
            dropOverhead(event.clone.dataset.id, event.to.dataset.id, event.newIndex + 1);
          }
        }
      });
    }
  }

  dropResourceType(resourceId, procedureId, position) {
    const body = {
      resource_time: {
        resource_type_id: resourceId,
        budget_procedure_id: procedureId,
        position: position
      }
    }

    post(`/resource_times`, { body: JSON.stringify(body), responseKind: "turbo-stream" })
  }

  dropCost(costTypeId, procedureId, position) {
    const body = {
      resource_cost: {
        cost_type_id: costTypeId,
        budget_procedure_id: procedureId,
        position: position
      }
    }

    post(`/resource_costs`, { body: JSON.stringify(body), responseKind: "turbo-stream" })
  }

  dropOverhead(overheadId, procedureId, position) {
    const body = {
      resource_overhead: {
        business_overhead_id: overheadId,
        budget_procedure_id: procedureId,
        position: position
      }
    }

    post(`/resource_overheads`, { body: JSON.stringify(body), responseKind: "turbo-stream" })
  }

  addLoader(event) {
    event.item.outerHTML = "<div class='my-3 text-sm text-slate-400 flex justify-center items-center p-3 rounded-lg bg-white'>Adding Resource...<div>";
  }

  showResources() {
    this.resourcesLinkTarget.classList.add("tab--active");

    this.costsLinkTarget.classList.remove("tab--active");
    this.overheadsLinkTarget.classList.remove("tab--active");

    this.resourcesTarget.classList.remove("hidden");
    this.resourcesTarget.classList.add("flex");

    this.costsTarget.classList.add("hidden");
    this.overheadsTarget.classList.add("hidden");
  }

  showCosts() {
    this.costsLinkTarget.classList.add("tab--active");

    this.resourcesLinkTarget.classList.remove("tab--active");
    this.overheadsLinkTarget.classList.remove("tab--active");

    this.costsTarget.classList.remove("hidden");
    this.costsTarget.classList.add("flex");

    this.resourcesTarget.classList.add("hidden");
    this.overheadsTarget.classList.add("hidden");
  }

  showOverheads() {
    this.overheadsLinkTarget.classList.add("tab--active");

    this.resourcesLinkTarget.classList.remove("tab--active");
    this.costsLinkTarget.classList.remove("tab--active");

    this.overheadsTarget.classList.remove("hidden");
    this.overheadsTarget.classList.add("flex");

    this.resourcesTarget.classList.add("hidden");
    this.costsTarget.classList.add("hidden");
  }
}
